import React from "react";
import group from "../images/ecell/about-bg.jpeg";
import Layout from "../components/layout";
import { Link } from "gatsby";

const AboutPage = () => (
  <Layout>
    <section id="about-page-head" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">About Us</h2>

              <ul className="list-unstyled list-inline">
                <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                <li className="list-inline-item"><a>/</a></li>
                <li className="list-inline-item"><a href="#" className="text-muted">About Us</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-page" className="section about-page">

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">About E-CELL</h6>
              <h2 className="display-4">Promoting Entrepreneurship</h2>
            </div>
          </div>
        </div>

        <div className="row">
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>At E-Cell, we aim to provide both graduate and under-graduate a preferred ecosystem to discover the
              entrepreneur within. We provide platform for individual to develop leadership skills, art of managing
              events, social development and interaction. Also, efficient interaction between students, faculty and
              working professional with existing Entrepreneurs, professional speakers, venture capitalists and several
              other industrialists. </p>
            <h4>Why associate with us?</h4>
            <p>E-Cell is a Non-Profit Student organization promoting entrepreneurship among students at Chitkara
              University, Punjab. Run by the students under the guidance of dedicated mentors.</p>
            <p>Not just this, we organize a variety of events for exposing budding entrepreneurs to the entrepreneurial
              ecosystem. We have organized several successful flagship events. Few includes Surge 0.1, Startup Day, earn
              while you learn. Besides, organizing events our team do regular meetings and sessions to discuss and
              interact about upcoming events.Entrepreneurship Cell, here believes in promoting entrepreneurial mindset
              in students with hidden potential. Students get a sense of professionalism and learn the importance of
              social development. The key in managing resources is inculcated in the students. An ecosystem with
              mentors, budding entrepreneurs, angel investors, industry experts and venture capitalists is being
              developed with a goal of building an enterprising India.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
